import React from 'react'
import './fourofour.css'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getFouroFourImage = graphql`
  {
    fluid: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
    `

const FouroFour = () => {
    const data = useStaticQuery(getFouroFourImage);
    return (
        <div className='four-wrap'>
            <div className="container">
                <div className="row">
                <div className="col-md-6 mx-auto py-5 four-adjust">
                <Img fluid={data.fluid.childImageSharp.fluid} />
                
                </div>

                
                </div>
                <div className="row">
                <h1 className="row-four mx-auto">You just hit a route that doesn&#39;t exist... the sadness.</h1>
                
                </div>
                
                
            </div>
        </div>
    )
}

export default FouroFour
