import React from "react"
import FouroFour from "../components/fourofour/fourofour"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 | Techvizon" pathname="/404" description="Not Found" />
    <FouroFour/>
    {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
  </Layout>
)

export default NotFoundPage
